<template>
  <v-row class="secondary--text">
    <v-col
      cols="12"
      md="10">
      <v-row class="pl-4">
        <template v-for="(p, i) in formData.packages">
          <v-col
            :key="`package-start-date-${i}`"
            cols="12"
            md="5">
            <div class="mb-1">
              วันเดินทางไป
            </div>
            <DatePickerDialog
              v-model="formData.packages[i].startDate"
              format="D MMM BBBB"
              :min="today"
              :max="formData.packages[i].endDate"
              :rules="textBoxRules"
              :disabled="loading || readonly"
              @input="selectPackageDate($event, i)" />
          </v-col>
          <v-col
            :key="`package-end-date-${i}`"
            cols="12"
            md="7">
            <div class="mb-1">
              วันเดินทางกลับ
            </div>
            <div
              class="d-flex flex-row align-center"
              style="gap: 1rem;">
              <DatePickerDialog
                v-model="formData.packages[i].endDate"
                format="D MMM BBBB"
                :min="formData.packages[i].startDate || today"
                :rules="textBoxRules"
                :disabled="loading || readonly" />
              <div class="d-flex flex-row align-center pl-4">
                <span class="mr-4">
                  ปิด
                </span>
                <v-switch
                  v-model="formData.packages[i].status"
                  true-value="active"
                  false-value="inactive"
                  inset
                  hide-details
                  :disabled="loading || readonly"
                  class="mt-0 pt-0" />
                <span>
                  เปิด
                </span>
              </div>
              <v-btn
                color="error"
                icon
                :disabled="formData.packages.length <= 1 || readonly"
                @click="removePackage(i)">
                <v-icon>
                  mdi-trash-can-outline
                </v-icon>
              </v-btn>
            </div>
          </v-col>
        </template>
      </v-row>
      <div class="d-flex justify-end mt-4">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          :disabled="loading || readonly"
          @click="addPackage()">
          <v-icon>
            mdi-plus-circle-outline
          </v-icon>
          <span>
            เพิ่มรอบการเดินทาง
          </span>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import DatePickerDialog from '@/components/DatePickerDialog.vue'

export default {
  components: { DatePickerDialog },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    today: new Date().toISOString()
  }),
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    user () {
      return this.$store.getters['User/user']
    },
    role () {
      return this.user?.role || 'alltotrips'
    }
  },
  methods: {
    addPackage () {
      this.formData.packages.push({
        startDate: '',
        endDate: '',
        adult: {
          price: 0,
          withOutTicket: 0
        },
        kid: {
          price: 0,
          withOutTicket: 0
        },
        status: 'active'
      })
    },
    removePackage (index) {
      if (index === 0) {
        this.formData.packages[1] = {
          ...this.formData.packages[1],
          adult: {
            price: this.formData.packages[0].adult.price,
            withOutTicket: this.formData.packages[0].adult.withOutTicket
          },
          kid: {
            price: this.formData.packages[0].kid.price,
            withOutTicket: this.formData.packages[0].kid.withOutTicket
          }
        }
      }

      this.formData.packages.splice(index, 1)
    },
    selectPackageDate (val, index) {
      this.formData.packages[index].endDate = this.$dayjs(val).add(this.formData.duration.days - 1, 'd').toISOString()
    }
  }
}
</script>

<style scoped>

</style>
