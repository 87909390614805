<template>
  <v-row class="secondary--text">
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col cols="12">
      <image-uploader
        v-model="formData.imageUrls"
        name="tour-images"
        label="รูปภาพแพ็กเกจทัวร์"
        :max-file="5"
        multiple
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      :md="formData.id ? 'auto' : '10'">
      <div class="mb-1">
        เลขที่แพ็กเกจทัวร์
      </div>
      <div v-if="formData.id">
        {{ formData.id }}
      </div>
      <div v-else>
        ATT - XXXX
      </div>
    </v-col>
    <v-col
      v-if="formData.id"
      cols="12"
      md="auto">
      <div class="mb-1">
        แสดง Sold out
      </div>
      <div class="d-flex flex-row align-center pl-4">
        <span class="mr-4">
          ปิด
        </span>
        <v-switch
          v-model="formData.soldOut"
          inset
          hide-details
          class="mt-0 pt-0"
          :disabled="loading || readonly" />
        <span>
          เปิด
        </span>
      </div>
    </v-col>
    <v-spacer />
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        เวนเดอร์
      </div>
      <v-autocomplete
        v-model="formData.vendor"
        :items="vendors"
        item-text="name"
        item-value="id"
        :rules="textBoxRules"
        outlined
        dense
        return-object
        label="เวนเดอร์"
        placeholder="โปรดเลือก"
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        ชื่อแพ็กเกจทัวร์
      </div>
      <v-text-field
        v-model="formData.name"
        :rules="textBoxRules"
        placeholder="EGYPT ไคโร กีซา เมมฟิส อเล็กซานเดรีย"
        color="primary"
        outlined
        dense
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        คำอธิบาย
      </div>
      <v-textarea
        v-model="formData.description"
        :rules="textBoxRules"
        placeholder="ชมเมืองอเล็กซานเดรีย ตำนานความรักของพระนางคลีโอพัตรา สัมผัสความยิ่งใหญ่ของมหาพีระมิดแห่งกีซา..."
        color="primary"
        outlined
        dense
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="5">
      <div class="mb-1">
        ทวีป
      </div>
      <v-autocomplete
        v-model="formData.destination.continent"
        :items="continents"
        item-text="text"
        item-value="value"
        :rules="textBoxRules"
        outlined
        dense
        placeholder="โปรดเลือก"
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="5">
      <div class="mb-1">
        ประเทศ
      </div>
      <v-autocomplete
        v-model="formData.destination.country"
        :items="filterCountries"
        item-text="text"
        item-value="value"
        :rules="textBoxRules"
        outlined
        dense
        placeholder="โปรดเลือก"
        hide-details
        required
        :disabled="loading || !formData.destination.continent || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <image-uploader
        v-model="formData.galleries"
        name="tour-galleries-images"
        label="แกลเลอรี"
        :max-file="30"
        multiple
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <span>
        ราคาทัวร์รวมตั๋วเครื่องบิน
      </span>
      <v-divider class="my-3" />
      <v-row class="pl-4">
        <v-col cols="12">
          <div class="mb-1">
            ราคาทัวร์ผู้ใหญ่
          </div>
          <v-text-field
            v-model.number="formData.packages[0].adult.price"
            type="number"
            color="primary"
            outlined
            dense
            required
            hide-details
            append-icon="mdi-currency-thb"
            :disabled="loading || readonly" />
        </v-col>
        <v-col cols="12">
          <div class="mb-1">
            ราคาทัวร์เด็ก
          </div>
          <v-text-field
            v-model.number="formData.packages[0].kid.price"
            type="number"
            color="primary"
            outlined
            dense
            required
            hide-details
            append-icon="mdi-currency-thb"
            :disabled="loading || readonly" />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="10">
      <span>
        ราคาทัวร์ไม่รวมตั๋วเครื่องบิน
      </span>
      <v-divider class="my-3" />
      <v-row class="pl-4">
        <v-col cols="12">
          <div class="mb-1">
            ราคาทัวร์ผู้ใหญ่
          </div>
          <v-text-field
            v-model.number="formData.packages[0].adult.withOutTicket"
            type="number"
            color="primary"
            outlined
            dense
            required
            hide-details
            append-icon="mdi-currency-thb"
            :disabled="loading || readonly" />
        </v-col>
        <v-col cols="12">
          <div class="mb-1">
            ราคาทัวร์เด็ก
          </div>
          <v-text-field
            v-model.number="formData.packages[0].kid.withOutTicket"
            type="number"
            color="primary"
            outlined
            dense
            required
            hide-details
            append-icon="mdi-currency-thb"
            :disabled="loading || readonly" />
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="10">
      <span>
        ประเภทห้อง
      </span>
      <v-divider class="mt-3 mb-4" />
      <v-row class="pl-4">
        <template v-for="(room, r) in formData.rooms">
          <v-col
            :key="`name-${r}`"
            cols="12"
            md="4">
            <v-select
              v-model="formData.rooms[r].name"
              :items="filterRooms"
              item-text="name"
              item-value="name"
              color="primary"
              label="ประเภทห้อง"
              placeholder="โปรดเลือก"
              outlined
              dense
              required
              hide-details
              :rules="textBoxRules"
              :disabled="loading || readonly" />
          </v-col>
          <v-col
            :key="`capacity-${r}`"
            cols="12"
            md="4">
            <v-text-field
              v-model.number="formData.rooms[r].capacity"
              type="number"
              color="primary"
              label="จำนวนคน"
              outlined
              dense
              required
              hide-details
              :disabled="loading || readonly" />
          </v-col>
          <v-col
            :key="`amount-${r}`"
            cols="12"
            md="4"
            class="d-flex flex-row align-center"
            style="gap: 1rem;">
            <v-text-field
              v-model.number="formData.rooms[r].price"
              type="number"
              color="primary"
              label="ราคา"
              outlined
              dense
              required
              hide-details
              append-icon="mdi-currency-thb"
              :disabled="loading || readonly" />
            <v-btn
              color="error"
              icon
              :disabled="formData.rooms.length <= 1 || readonly"
              @click="removeRoom(r)">
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </v-col>
        </template>
      </v-row>
      <div class="d-flex justify-end mt-4">
        <v-btn
          style="width: fit-content; background-color: white;"
          color="primary"
          outlined
          depressed
          :ripple="false"
          :disabled="formData.rooms.length === rooms.length || readonly"
          @click="addRoom()">
          <v-icon>
            mdi-plus-circle-outline
          </v-icon>
          <span>
            เพิ่มประเภทห้อง
          </span>
        </v-btn>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        แท็กของแพ็กเกจ (ไม่เกิน 5 แท็ก)
      </div>
      <v-autocomplete
        v-model="formData.tags"
        :items="tags"
        item-text="name"
        item-value="name"
        :rules="textBoxRules"
        multiple
        small-chips
        deletable-chips
        outlined
        dense
        label="แท็ก"
        placeholder="โปรดเลือก"
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        ประเภททัวร์
      </div>
      <v-autocomplete
        v-model="formData.type"
        :items="tourTypes"
        item-text="name"
        item-value="name"
        :rules="textBoxRules"
        outlined
        dense
        label="ประเภททัวร์"
        placeholder="โปรดเลือกประเภททัวร์"
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="5">
      <div class="mb-1">
        ระยะเวลา
      </div>
      <v-row>
        <v-col
          cols="12"
          md="6">
          <v-select
            v-model.number="formData.duration.days"
            :items="durationAmount"
            :rules="textBoxRules"
            outlined
            dense
            label="วัน"
            placeholder="6"
            hide-details
            required
            append-outer-icon="mdi-weather-sunny"
            :disabled="loading || readonly"
            @input="selectDuration($event)">
            <template #append-outer>
              <span class="mt-1">
                วัน
              </span>
            </template>
          </v-select>
        </v-col>
        <v-col
          cols="12"
          md="6">
          <v-select
            v-model.number="formData.duration.nights"
            :items="durationAmount"
            :rules="textBoxRules"
            outlined
            dense
            label="คืน"
            placeholder="3"
            hide-details
            required
            append-outer-icon="mdi-weather-night"
            :disabled="loading || readonly">
            <template #append-outer>
              <span class="mt-1">
                คืน
              </span>
            </template>
          </v-select>
        </v-col>
      </v-row>
    </v-col>
    <v-col
      cols="12"
      md="5">
      <div class="mb-1">
        วิธีการเดินทาง
      </div>
      <v-autocomplete
        v-model="formData.airline"
        :items="airlines"
        item-text="name"
        item-value="name"
        :rules="textBoxRules"
        outlined
        dense
        label="สายการบิน"
        placeholder="สายการบิน GULF AIR"
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="5">
      <div class="mb-1">
        ที่พัก
      </div>
      <v-select
        v-model="formData.accommodation.type"
        :items="residentTypes"
        item-text="name"
        item-value="name"
        :rules="textBoxRules"
        outlined
        dense
        label="ที่พัก"
        placeholder="โรงแรม"
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="5">
      <div class="mb-1">
        ระดับดาวที่พัก
      </div>
      <v-select
        v-model.number="formData.accommodation.star"
        :items="accommodationStars"
        :rules="textBoxRules"
        outlined
        dense
        label="ระดับดาวที่พัก"
        placeholder="5"
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="5">
      <div class="mb-1">
        อาหาร
      </div>
      <v-text-field
        v-model="formData.meal"
        :rules="textBoxRules"
        placeholder="อาหาร 8 มื้อ"
        color="primary"
        outlined
        dense
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="5">
      <div class="mb-1">
        จำนวนคนเดินทาง
      </div>
      <v-text-field
        v-model.number="formData.crews"
        type="number"
        :rules="textBoxRules"
        placeholder="15"
        color="primary"
        outlined
        dense
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        สิ่งอำนวยความสะดวก พิเศษ
      </div>
      <v-row class="pt-4">
        <template v-for="(sp, r) in formData.specialOptions">
          <v-col
            :key="`special-option-${r}`"
            cols="12"
            class="d-flex flex-row align-center"
            style="gap: 1rem;">
            <span>
              {{ r + 1 }}.
            </span>
            <v-select
              v-model="formData.specialOptions[r]"
              :items="specialOptions"
              item-text="text"
              item-value="value"
              color="primary"
              placeholder="โปรดเลือก"
              outlined
              dense
              required
              hide-details
              :disabled="loading || readonly" />
            <v-btn
              color="error"
              icon
              :disabled="formData.specialOptions.length <= 1 || loading || readonly"
              @click="removeSpecialOption(r)">
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </v-col>
        </template>
      </v-row>
      <div class="d-flex justify-end mt-4">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          :disabled="loading || readonly"
          @click="addSpecialOption()">
          <v-icon>
            mdi-plus-circle-outline
          </v-icon>
          <span>
            เพิ่มข้อมูล
          </span>
        </v-btn>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        เอกสารเพิ่มเติม
      </div>
      <file-uploader
        v-model="formData.additionalDocuments"
        :max-file="1"
        :max-size="15"
        label="อัปโหลดเอกสาร"
        :disabled="loading || readonly" />
      <div
        class="accent--text mt-1"
        style="font-size: 12px">
        ไฟล์ PDF ไม่เกิน 15 MB
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ImageUploader from '@/components/ImageUploader.vue'
import FileUploader from '@/components/FileUploader.vue'
import VendorsProvider from '@/resources/vendors.provider'
import CountriesProvider from '@/resources/countries.provider'
import TagsProvider from '@/resources/tags.provider'
import TourTypesProvider from '@/resources/tour-types.provider'
import ResidentTypesProvider from '@/resources/resident-types.provider'
import AirlinesProvider from '@/resources/airlines.provider'
import AmenitiesProvider from '@/resources/amenities.provider'
import { translateCountry, translateContinent } from '@/utils/translates.utils'

const VendorsService = new VendorsProvider()
const CountriesService = new CountriesProvider()
const TagsService = new TagsProvider()
const TourTypesService = new TourTypesProvider()
const ResidentTypesService = new ResidentTypesProvider()
const AirlinesService = new AirlinesProvider()
const AmenitiesService = new AmenitiesProvider()

export default {
  components: {
    ImageUploader,
    FileUploader
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    rooms: [
      {
        name: 'พักคู่ (TWIN BED)',
        price: 0,
        capacity: 2
      },
      {
        name: 'พักคู่ (DOUBLE BED)',
        price: 0,
        capacity: 2
      },
      {
        name: 'พัก 3 ท่าน (TRIPLE BED)',
        price: 0,
        capacity: 3
      },
      {
        name: 'พักเดี่ยว (SINGLE BED)',
        price: 0,
        capacity: 1
      }
    ],
    vendors: [],
    continents: [],
    countries: [],
    tags: [],
    tourTypes: [],
    residentTypes: [],
    airlines: [],
    durationAmount: [
      1, 2, 3, 4, 5,
      6, 7, 8, 9, 10,
      11, 12, 13, 14, 15,
      16, 17, 18, 19, 20,
      21, 22, 23, 24, 25,
      26, 27, 28, 29, 30
    ],
    accommodationStars: [1, 2, 3, 4, 5],
    specialOptions: [],
    today: new Date().toISOString()
  }),
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    },
    user () {
      return this.$store.getters['User/user']
    },
    role () {
      return this.user?.role || 'alltotrips'
    },
    filterCountries () {
      return this.countries.filter((c) => c.continent === this.formData.destination.continent)
    },
    filterRooms () {
      const currentRooms = this.formData.rooms.map((r) => r.name).filter(Boolean)

      return this.rooms.map((r) => ({
        ...r,
        disabled: currentRooms.includes(r.name)
      }))
    }
  },
  mounted () {
    this.getAttributes()
  },
  methods: {
    addRoom () {
      this.formData.rooms.push({
        name: '',
        price: 0,
        capacity: 1
      })
    },
    removeRoom (index) {
      this.formData.rooms.splice(index, 1)
    },
    addSpecialOption () {
      this.formData.specialOptions.push('')
    },
    removeSpecialOption (index) {
      this.formData.specialOptions.splice(index, 1)
    },
    selectDuration (val) {
      this.formData.packages = this.formData.packages.map((p) => {
        if (!p.startDate) {
          return p
        }

        return {
          ...p,
          endDate: this.$dayjs(p.startDate).add(val - 1, 'd').toISOString()
        }
      })
    },
    async getAttributes () {
      try {
        this.loading = true

        await Promise.all([
          this.getVendors(),
          this.getCountries(),
          this.getTags(),
          this.getTourTypes(),
          this.getResidentTypes(),
          this.getAirlines(),
          this.getAmenities()
        ])
      } catch (error) {
        console.error('getAttributes', error)
      } finally {
        this.loading = false
      }
    },
    async getVendors () {
      try {
        this.loading = true

        if (this.role === 'vendor') {
          const { data } = await VendorsService.getItemById(this.user.vendor.id)

          this.vendors = data ? [data] : []
          this.formData.vendor = {
            id: data.id,
            name: data.name,
            vendorNo: data.vendorNo
          }
        } else {
          const { data } = await VendorsService.getItems({
            page: 1,
            limit: 99999
          })

          this.vendors = data.results
        }
      } catch (error) {
        console.error('getVendors', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getCountries () {
      try {
        this.loading = true

        const { data } = await CountriesService.getItems({
          page: 1,
          limit: 99999
        })

        const uniqueContinents = [...new Set(data.results.map((c) => c.continent))]

        this.continents = uniqueContinents.map((c) => ({
          text: translateContinent(c),
          value: c
        }))
        this.countries = data.results.map((c) => ({
          text: translateCountry(c.country),
          value: c.country,
          continent: c.continent
        }))
      } catch (error) {
        console.error('getCountries', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getTags () {
      try {
        this.loading = true

        const { data } = await TagsService.getItems({
          page: 1,
          limit: 99999
        })

        this.tags = data.results
      } catch (error) {
        console.error('getTags', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getTourTypes () {
      try {
        this.loading = true

        const { data } = await TourTypesService.getItems({
          page: 1,
          limit: 99999
        })

        this.tourTypes = data.results
      } catch (error) {
        console.error('getTourTypes', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getResidentTypes () {
      try {
        this.loading = true

        const { data } = await ResidentTypesService.getItems({
          page: 1,
          limit: 99999
        })

        this.residentTypes = data.results
      } catch (error) {
        console.error('getResidentTypes', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getAirlines () {
      try {
        this.loading = true

        const { data } = await AirlinesService.getItems({
          page: 1,
          limit: 99999
        })

        this.airlines = data.results
      } catch (error) {
        console.error('getAirlines', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async getAmenities () {
      try {
        this.loading = true

        const { data } = await AmenitiesService.getItems({
          page: 1,
          limit: 99999
        })

        this.specialOptions = [
          {
            text: 'ไม่ระบุ',
            value: ''
          },
          ...data.results.map((r) => ({
            text: r.name,
            value: r.name
          }))
        ]
      } catch (error) {
        console.error('getAmenities', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>

</style>
