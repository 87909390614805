<template>
  <v-row class="secondary--text">
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col
      v-for="(plan, f) in formData.plans"
      :key="`plan-${f}`"
      cols="12"
      md="10"
      class="pa-6">
      <v-row class="plan-container">
        <v-col cols="12">
          <div class="mb-1">
            Day
          </div>
          <v-text-field
            v-model="plan.day"
            :rules="textBoxRules"
            color="primary"
            outlined
            dense
            hide-details
            required
            :disabled="loading || readonly" />
          <v-divider class="mt-3" />
        </v-col>
        <v-col cols="12">
          <div class="mb-1">
            Location
          </div>
          <v-row class="pt-4">
            <template v-for="(sp, r) in plan.locations">
              <v-col
                :key="`location-${r}`"
                cols="12"
                class="d-flex flex-row align-center"
                style="gap: 1rem;">
                <span>
                  {{ r + 1 }}.
                </span>
                <v-text-field
                  v-model="plan.locations[r]"
                  :rules="textBoxRules"
                  placeholder="สนามบินบาห์เรน"
                  color="primary"
                  outlined
                  dense
                  hide-details
                  required
                  :disabled="loading || readonly" />
                <v-btn
                  color="error"
                  icon
                  :disabled="plan.locations.length <= 1 || readonly"
                  @click="removeLocation(f, r)">
                  <v-icon>
                    mdi-trash-can-outline
                  </v-icon>
                </v-btn>
              </v-col>
            </template>
          </v-row>
          <div class="d-flex justify-end mt-4">
            <v-btn
              color="primary"
              outlined
              depressed
              :ripple="false"
              style="width: fit-content; background-color: white;"
              :disabled="loading || readonly"
              @click="addLocation(f)">
              <v-icon>
                mdi-plus-circle-outline
              </v-icon>
              <span>
                เพิ่มข้อมูล
              </span>
            </v-btn>
          </div>
        </v-col>
        <v-col cols="12">
          <v-divider class="mb-3" />
          <v-data-table
            :headers="headers"
            :items="plan.contents"
            :items-per-page="-1"
            hide-default-footer>
            <template #[`item.time`]="{ index }">
              <div class="w-full h-full py-4">
                <v-text-field
                  v-model="plan.contents[index].time"
                  color="primary"
                  outlined
                  dense
                  hide-details
                  :disabled="loading || readonly" />
              </div>
            </template>
            <template #[`item.icon`]="{ index }">
              <div class="w-full h-full py-4">
                <v-btn
                  v-if="plan.contents[index].icon"
                  color="secondary"
                  icon
                  large
                  :ripple="false"
                  :disabled="loading || readonly"
                  @click="toggleIconLib(f, index)">
                  <v-icon large>
                    {{ plan.contents[index].icon }}
                  </v-icon>
                </v-btn>
                <v-btn
                  v-else
                  color="primary"
                  outlined
                  depressed
                  :ripple="false"
                  style="width: fit-content;"
                  :disabled="loading || readonly"
                  @click="toggleIconLib(f, index)">
                  <span>
                    Icon Library
                  </span>
                </v-btn>
              </div>
            </template>
            <template #[`item.content`]="{ index }">
              <div class="w-full h-full py-4">
                <editor
                  v-model="plan.contents[index].content"
                  :disabled="loading || readonly" />
              </div>
            </template>
            <template #[`item.actions`]="{ index }">
              <div class="d-flex flex-column align-center justify-center">
                <v-btn
                  color="primary"
                  icon
                  :ripple="false"
                  :disabled="loading || readonly"
                  @click="addContent(f, index)">
                  <v-icon>
                    mdi-plus
                  </v-icon>
                </v-btn>
                <v-btn
                  color="error"
                  icon
                  :ripple="false"
                  :disabled="plan.contents.length <= 1 || readonly"
                  @click="removeContent(f, index)">
                  <v-icon>
                    mdi-minus
                  </v-icon>
                </v-btn>
              </div>
            </template>
          </v-data-table>
        </v-col>
        <v-col cols="12">
          <v-divider class="mb-3" />
          <div
            class="d-flex justify-end"
            style="gap: 1rem;">
            <v-btn
              v-if="(f + 1) === formData.plans.length"
              color="primary"
              outlined
              depressed
              :ripple="false"
              style="width: fit-content; background-color: white;"
              :disabled="loading || readonly"
              @click="addPlan()">
              <v-icon>
                mdi-plus-circle-outline
              </v-icon>
              <span>
                เพิ่มแผนการเดินทาง
              </span>
            </v-btn>
            <v-btn
              v-if="formData.plans.length > 1"
              color="error"
              outlined
              depressed
              :ripple="false"
              style="width: fit-content; background-color: white;"
              :disabled="loading || readonly"
              @click="removePlan(f)">
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
              <span>
                ลบแผนการเดินทาง
              </span>
            </v-btn>
          </div>
        </v-col>
      </v-row>
    </v-col>
    <v-dialog
      v-model="isShowDialog"
      max-width="360">
      <v-card>
        <v-card-title>
          เลือก Icon ที่ต้องการ
        </v-card-title>

        <v-card-text>
          <v-divider class="mb-3" />
          <v-row>
            <v-col
              cols="6"
              md="3">
              <v-btn
                color="secondary"
                icon
                x-large
                :disabled="loading || readonly"
                @click="selectIcon('mdi-airplane')">
                <v-icon x-large>
                  mdi-airplane
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              md="3">
              <v-btn
                color="secondary"
                icon
                x-large
                :disabled="loading || readonly"
                @click="selectIcon('mdi-map-marker')">
                <v-icon x-large>
                  mdi-map-marker
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              md="3">
              <v-btn
                color="secondary"
                icon
                x-large
                :disabled="loading || readonly"
                @click="selectIcon('mdi-bed-empty')">
                <v-icon x-large>
                  mdi-bed-empty
                </v-icon>
              </v-btn>
            </v-col>
            <v-col
              cols="6"
              md="3">
              <v-btn
                color="secondary"
                icon
                x-large
                :disabled="loading || readonly"
                @click="selectIcon('mdi-food-fork-drink')">
                <v-icon x-large>
                  mdi-food-fork-drink
                </v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import Editor from '@/components/Editor.vue'

export default {
  components: { Editor },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    headers: [
      {
        text: 'ช่วงเวลา',
        value: 'time',
        sortable: false,
        align: 'center',
        width: '150px'
      },
      {
        text: 'ไอค่อน',
        value: 'icon',
        sortable: false,
        align: 'center',
        width: '150px'
      },
      {
        text: 'รายละเอียด',
        value: 'content',
        sortable: false,
        align: 'center'
      },
      {
        text: '',
        value: 'actions',
        sortable: false,
        align: 'center',
        width: '100px'
      }
    ],
    selectedIconIndex: -1,
    selectedIconSubIndex: -1,
    isShowDialog: false
  }),
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    addPlan () {
      this.formData.plans.push({
          day: '',
          locations: [''],
          contents: [
            {
              time: '',
              icon: '',
              separate: false,
              content: ''
            }
          ]
        })
    },
    removePlan (index) {
      this.formData.plans.splice(index, 1)
    },
    addLocation (index) {
      this.formData.plans[index].locations.push('')
    },
    removeLocation (index, subIndex) {
      this.formData.plans[index].locations.splice(subIndex, 1)
    },
    addContent (index, subIndex) {
      this.formData.plans[index].contents.splice(
        subIndex + 1,
        0,
        {
          time: '',
          icon: '',
          separate: false,
          content: ''
        }
      )
    },
    removeContent (index, subIndex) {
      this.formData.plans[index].contents.splice(subIndex, 1)
    },
    toggleIconLib (index, subIndex) {
      this.selectedIconIndex = index
      this.selectedIconSubIndex = subIndex
      this.isShowDialog = true
    },
    selectIcon (icon) {
      this.formData.plans[this.selectedIconIndex].contents[this.selectedIconSubIndex].icon = icon
      this.isShowDialog = false
    }
  }
}
</script>

<style scoped>
.plan-container {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
.w-full {
  width: 100%;
}
.h-full {
  height: 100%;
}
</style>

<style>
.ql-toolbar.ql-snow {
  border-top-left-radius: 4px;
  border-top-right-radius: 4px;
}
.ql-container.ql-snow  {
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
}
</style>
