<template>
  <v-row class="secondary--text">
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <v-expansion-panels
        class="rounded-lg"
        style="border: 1px solid #026EAA;">
        <!-- <v-expansion-panel class="rounded-lg no-shadow-panel">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              ประกันภัย
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="secondary--text pb-6">
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col
                v-for="(insurance, f) in formData.insurances"
                :key="`insurance-${f}`"
                cols="12"
                class="px-6 pt-6">
                <v-row class="content-container">
                  <v-col cols="12">
                    <div class="mb-1">
                      ชื่อประกันภัย
                    </div>
                    <v-text-field
                      v-model="insurance.name"
                      :rules="textBoxRules"
                      placeholder="AXA Insurance"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required />
                  </v-col>
                  <v-col cols="12">
                    <div class="mb-1">
                      ราคา
                    </div>
                    <v-text-field
                      v-model.number="insurance.price"
                      type="number"
                      min="0"
                      color="primary"
                      outlined
                      dense
                      required
                      hide-details
                      append-icon="mdi-currency-thb"
                      :disabled="loading" />
                  </v-col>
                  <v-col cols="12">
                    <div class="mb-1">
                      รายละเอียด
                    </div>
                    <v-textarea
                      v-model="insurance.description"
                      :rules="textBoxRules"
                      placeholder="คำอธิบาย"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required />
                  </v-col>
                  <v-col cols="12">
                    <div class="mb-1">
                      ความคุ้มครอง
                    </div>
                    <v-textarea
                      v-model="insurance.protections"
                      :rules="textBoxRules"
                      placeholder="คำอธิบาย"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required />
                  </v-col>
                  <v-col cols="12">
                    <div
                      class="d-flex justify-end"
                      style="gap: 1rem;">
                      <v-btn
                        v-if="(f + 1) === formData.insurances.length"
                        color="primary"
                        outlined
                        depressed
                        :ripple="false"
                        style="width: fit-content; background-color: white;"
                        @click="addInsurance()">
                        <v-icon>
                          mdi-plus-circle-outline
                        </v-icon>
                        <span>
                          เพิ่มข้อมูล
                        </span>
                      </v-btn>
                      <v-btn
                        v-if="formData.insurances.length > 1"
                        color="error"
                        outlined
                        depressed
                        :ripple="false"
                        style="width: fit-content; background-color: white;"
                        @click="removeInsurance(f)">
                        <v-icon>
                          mdi-trash-can-outline
                        </v-icon>
                        <span>
                          ลบข้อมูล
                        </span>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel> -->
        <v-expansion-panel class="rounded-lg no-shadow-panel">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              อัพเกรดที่นั่ง
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="secondary--text pb-6">
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col
                v-for="(seat, f) in formData.seats"
                :key="`seat-${f}`"
                cols="12"
                class="px-6 pt-6">
                <v-row class="content-container">
                  <v-col cols="12">
                    <div class="mb-1">
                      ประเภทที่นั่ง
                    </div>
                    <v-text-field
                      v-model="seat.name"
                      placeholder="First class"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required
                      :disabled="loading || readonly" />
                  </v-col>
                  <v-col cols="12">
                    <div class="mb-1">
                      ราคา
                    </div>
                    <v-text-field
                      v-model.number="seat.price"
                      type="number"
                      min="0"
                      color="primary"
                      outlined
                      dense
                      required
                      hide-details
                      append-icon="mdi-currency-thb"
                      :disabled="loading || readonly" />
                  </v-col>
                  <v-col cols="12">
                    <div
                      class="d-flex justify-end"
                      style="gap: 1rem;">
                      <v-btn
                        v-if="(f + 1) === formData.seats.length"
                        color="primary"
                        outlined
                        depressed
                        :ripple="false"
                        style="width: fit-content; background-color: white;"
                        :disabled="loading || readonly"
                        @click="addSeat()">
                        <v-icon>
                          mdi-plus-circle-outline
                        </v-icon>
                        <span>
                          เพิ่มข้อมูล
                        </span>
                      </v-btn>
                      <v-btn
                        v-if="formData.seats.length > 1"
                        color="error"
                        outlined
                        depressed
                        :ripple="false"
                        style="width: fit-content; background-color: white;"
                        :disabled="loading || readonly"
                        @click="removeSeat(f)">
                        <v-icon>
                          mdi-trash-can-outline
                        </v-icon>
                        <span>
                          ลบข้อมูล
                        </span>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ]
  }),
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  methods: {
    addInsurance () {
      this.formData.insurances.push({
          name: '',
          description: '',
          protections: '',
          price: 0
        })
    },
    removeInsurance (index) {
      this.formData.insurances.splice(index, 1)
    },
    addSeat () {
      this.formData.seats.push({
          name: '',
          price: 0
        })
    },
    removeSeat (index) {
      this.formData.seats.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.v-expansion-panel::after {
  opacity: 0.7 !important;
}
.v-expansion-panel:not(:first-child)::after {
  /* border-top: 1px solid #026EAA !important; */
  border-top: none !important;
  width: calc(100% - 2rem);
  height: 1px;
  margin: 0 auto;
  background-color: #026EAA !important;
}
.no-shadow-panel::before {
  box-shadow: none !important;
}
.content-container {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
</style>
