<template>
  <v-row class="secondary--text">
    <v-col cols="12">
      <v-divider />
    </v-col>
    <v-col
      cols="12"
      md="10">
      <v-expansion-panels
        class="rounded-lg"
        style="border: 1px solid #026EAA;">
        <v-expansion-panel class="rounded-lg no-shadow-panel">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              ตารางเวลาเที่ยวบิน
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-row class="secondary--text pb-6">
              <v-col cols="12">
                <v-divider />
              </v-col>
              <v-col
                v-for="(flight, f) in formData.flights"
                :key="`flight-${f}`"
                cols="12"
                class="px-6 pt-6">
                <v-row class="flight-container">
                  <v-col cols="12">
                    <span>
                      {{ `ตารางเวลาเที่ยวบิน ${f + 1}` }}
                    </span>
                    <v-divider class="mt-3" />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6">
                    <div class="mb-1">
                      วันออกเดินทาง
                    </div>
                    <DatePickerDialog
                      v-model="flight.date"
                      format="D MMM BBBB"
                      :rules="textBoxRules"
                      :disabled="loading || readonly" />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6">
                    <div class="mb-1">
                      ชื่อไฟล์ทบิน
                    </div>
                    <v-text-field
                      v-model="flight.flightNo"
                      :rules="textBoxRules"
                      placeholder="ATT123"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required
                      :disabled="loading || readonly" />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6">
                    <div class="mb-1">
                      ต้นทาง
                    </div>
                    <v-text-field
                      v-model="flight.departure.location"
                      :rules="textBoxRules"
                      placeholder="กรุงเทพฯ"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required
                      :disabled="loading || readonly" />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6">
                    <div class="mb-1">
                      ปลายทาง
                    </div>
                    <v-text-field
                      v-model="flight.arrival.location"
                      :rules="textBoxRules"
                      placeholder="ฮอกไกโด"
                      color="primary"
                      outlined
                      dense
                      hide-details
                      required
                      :disabled="loading || readonly" />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6">
                    <div class="mb-1">
                      เวลาออก
                    </div>
                    <TimePickerDialog
                      v-model="flight.departure.time"
                      :rules="textBoxRules"
                      :disabled="loading || readonly" />
                  </v-col>
                  <v-col
                    cols="12"
                    md="6">
                    <div class="mb-1">
                      เวลาถึง
                    </div>
                    <TimePickerDialog
                      v-model="flight.arrival.time"
                      :rules="textBoxRules"
                      :disabled="loading || readonly" />
                  </v-col>
                  <v-col cols="12">
                    <image-uploader
                      v-model="flight.seatMaps"
                      :name="`seat-maps-${f}`"
                      multiple
                      :max-file="10"
                      label="รูปภาพ"
                      :disabled="loading || readonly" />
                  </v-col>
                  <v-col cols="12">
                    <div
                      class="d-flex justify-end"
                      style="gap: 1rem;">
                      <v-btn
                        v-if="(f + 1) === formData.flights.length"
                        color="primary"
                        outlined
                        depressed
                        :ripple="false"
                        style="width: fit-content; background-color: white;"
                        :disabled="loading || readonly"
                        @click="addFlight()">
                        <v-icon>
                          mdi-plus-circle-outline
                        </v-icon>
                        <span>
                          เพิ่มตารางเที่ยวบิน
                        </span>
                      </v-btn>
                      <v-btn
                        v-if="formData.flights.length > 1"
                        color="error"
                        outlined
                        depressed
                        :ripple="false"
                        style="width: fit-content; background-color: white;"
                        :disabled="loading || readonly"
                        @click="removeFlight(f)">
                        <v-icon>
                          mdi-trash-can-outline
                        </v-icon>
                        <span>
                          ลบตารางเที่ยวบิน
                        </span>
                      </v-btn>
                    </div>
                  </v-col>
                </v-row>
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        เงื่อนไขเพิ่มเติมของอัตราค่าบริการ
      </div>
      <v-textarea
        v-model="formData.additionalRateConditions.th"
        placeholder="พิมพ์ตรงนี้ได้เลย..."
        color="primary"
        outlined
        dense
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col>
    <!-- <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        เงื่อนไขเพิ่มเติมของอัตราค่าบริการ (อังกฤษ)
      </div>
      <v-textarea
        v-model="formData.additionalRateConditions.en"
        :rules="textBoxRules"
        placeholder="Type something here..."
        color="primary"
        outlined
        dense
        hide-details
        required
        :disabled="loading || readonly" />
    </v-col> -->
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        อัตราค่าบริการนี้ <u>รวม</u>
      </div>
      <v-row class="pt-4">
        <template v-for="(sp, r) in formData.additionalRateIncludes">
          <v-col
            :key="`additional-rate-include-${r}`"
            cols="12"
            class="d-flex flex-row align-center"
            style="gap: 1rem;">
            <span>
              {{ r + 1 }}.
            </span>
            <v-select
              v-model="formData.additionalRateIncludes[r]"
              :items="serviceRates"
              item-text="name"
              item-value="name"
              color="primary"
              placeholder="โปรดเลือก"
              outlined
              dense
              required
              hide-details
              :disabled="loading || readonly" />
            <v-btn
              color="error"
              icon
              :disabled="formData.additionalRateIncludes.length <= 1 || loading || readonly"
              @click="removeAdditionalRateInclude(r)">
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </v-col>
        </template>
      </v-row>
      <div class="d-flex justify-end mt-4">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          :disabled="loading || readonly"
          @click="addAdditionalRateInclude()">
          <v-icon>
            mdi-plus-circle-outline
          </v-icon>
          <span>
            เพิ่มข้อมูล
          </span>
        </v-btn>
      </div>
    </v-col>
    <v-col
      cols="12"
      md="10">
      <div class="mb-1">
        อัตราค่าบริการนี้ <u>ไม่รวม</u>
      </div>
      <v-row class="pt-4">
        <template v-for="(sp, r) in formData.additionalRateNotIncludes">
          <v-col
            :key="`additional-rate-include-${r}`"
            cols="12"
            class="d-flex flex-row align-center"
            style="gap: 1rem;">
            <span>
              {{ r + 1 }}.
            </span>
            <v-select
              v-model="formData.additionalRateNotIncludes[r]"
              :items="serviceRates"
              item-text="name"
              item-value="name"
              color="primary"
              placeholder="โปรดเลือก"
              outlined
              dense
              required
              hide-details
              :disabled="loading || readonly" />
            <v-btn
              color="error"
              icon
              :disabled="formData.additionalRateNotIncludes.length <= 1 || loading || readonly"
              @click="removeAdditionalRateNotInclude(r)">
              <v-icon>
                mdi-trash-can-outline
              </v-icon>
            </v-btn>
          </v-col>
        </template>
      </v-row>
      <div class="d-flex justify-end mt-4">
        <v-btn
          color="primary"
          outlined
          depressed
          :ripple="false"
          style="width: fit-content; background-color: white;"
          :disabled="loading || readonly"
          @click="addAdditionalRateNotInclude()">
          <v-icon>
            mdi-plus-circle-outline
          </v-icon>
          <span>
            เพิ่มข้อมูล
          </span>
        </v-btn>
      </div>
    </v-col>
  </v-row>
</template>

<script>
import ImageUploader from '@/components/ImageUploader.vue'
import DatePickerDialog from '@/components/DatePickerDialog.vue'
import TimePickerDialog from '@/components/TimePickerDialog.vue'
import ServiceRatesProvider from '@/resources/service-rates.provider'

const ServiceRatesService = new ServiceRatesProvider()

export default {
  components: {
    ImageUploader,
    DatePickerDialog,
    TimePickerDialog
  },
  props: {
    value: {
      type: Object,
      default: () => {}
    },
    readonly: {
      type: Boolean,
      default: false
    }
  },
  data: () => ({
    loading: false,
    textBoxRules: [
      (v) => !!v || 'กรุณากรอกข้อมูลให้ครบถ้วน'
    ],
    serviceRates: []
  }),
  computed: {
    formData: {
      get () {
        return this.value
      },
      set (val) {
        this.$emit('input', val)
      }
    }
  },
  mounted () {
    this.getServiceRates()
  },
  methods: {
    async getServiceRates () {
      try {
        this.loading = true

        const { data } = await ServiceRatesService.getItems({
          page: 1,
          limit: 99999
        })

        this.serviceRates = data.results
      } catch (error) {
        console.error('getServiceRates', error)
        this.setSnackbar({
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    addFlight () {
      this.formData.flights.push({
          date: '',
          flightNo: '',
          seatMaps: [],
          departure: {
            location: '',
            time: ''
          },
          arrival: {
            location: '',
            time: ''
          }
        })
    },
    removeFlight (index) {
      this.formData.flights.splice(index, 1)
    },
    addAdditionalRateInclude () {
      this.formData.additionalRateIncludes.push('')
    },
    removeAdditionalRateInclude (index) {
      this.formData.additionalRateIncludes.splice(index, 1)
    },
    addAdditionalRateNotInclude () {
      this.formData.additionalRateNotIncludes.push('')
    },
    removeAdditionalRateNotInclude (index) {
      this.formData.additionalRateNotIncludes.splice(index, 1)
    }
  }
}
</script>

<style scoped>
.no-shadow-panel::before {
  box-shadow: none !important;
}
.flight-container {
  border: 1px solid #E0E0E0;
  border-radius: 8px;
}
</style>
