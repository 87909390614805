<template>
  <v-container fluid>
    <v-form
      ref="form"
      v-model="validate"
      @submit.prevent="onSubmit()">
      <v-expansion-panels
        class="mb-4"
        style="gap: 1rem;">
        <v-expansion-panel class="rounded-lg">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              สร้างแพ็กเกจ
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <create-package-section v-model="formData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="rounded-lg">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              รอบการเดินทาง
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <period-package-section v-model="formData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="rounded-lg">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              รายละเอียดแพ็กเกจ
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <detail-package-section v-model="formData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="rounded-lg">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              แผนการเดินทาง
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <plan-package-section v-model="formData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="rounded-lg">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              เงื่อนไขและข้อมูลการเดินทาง
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <conditions-package-section v-model="formData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="rounded-lg">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              สถานที่เที่ยวไฮไลท์สำคัญ
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <highlight-package-section v-model="formData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel class="rounded-lg">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              บริการเพิ่มเติม
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <additional-package-section v-model="formData" />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="isEdit"
          class="rounded-lg">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              ประวัติการจอง
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <order-histories-section />
          </v-expansion-panel-content>
        </v-expansion-panel>
        <v-expansion-panel
          v-if="isEdit"
          class="rounded-lg">
          <v-expansion-panel-header>
            <h3 class="primary--text">
              รีวิว
            </h3>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <reviews-package-section
              :tour-name="formData.name"
              :packages="formData.packages"
              :readonly="role !== 'administrator'" />
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-row>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            outlined
            block
            :ripple="false"
            depressed
            style="background-color: white;"
            :disabled="loading"
            @click="onBack()">
            ยกเลิก
          </v-btn>
        </v-col>
        <v-col
          cols="6"
          md="3">
          <v-btn
            color="primary"
            block
            :ripple="false"
            depressed
            :loading="loading"
            @click="onSubmit()">
            บันทึก
          </v-btn>
        </v-col>
      </v-row>
    </v-form>
  </v-container>
</template>

<script>
import ToursProvider from '@/resources/tours.provider'
import UploaderProvider from '@/resources/uploader.provider'
import CreatePackageSection from '../components/CreatePackageSection.vue'
import PeriodPackageSection from '../components/PeriodPackageSection.vue'
import DetailPackageSection from '../components/DetailPackageSection.vue'
import PlanPackageSection from '../components/PlanPackageSection.vue'
import ConditionsPackageSection from '../components/ConditionsPackageSection.vue'
import HighlightPackageSection from '../components/HighlightPackageSection.vue'
import AdditionalPackageSection from '../components/AdditionalPackageSection.vue'
import OrderHistoriesSection from '../components/OrderHistoriesSection.vue'
import ReviewsPackageSection from '../components/ReviewsPackageSection.vue'

const ToursService = new ToursProvider()
const UploaderService = new UploaderProvider()

export default {
  components: {
    CreatePackageSection,
    PeriodPackageSection,
    DetailPackageSection,
    PlanPackageSection,
    ConditionsPackageSection,
    HighlightPackageSection,
    AdditionalPackageSection,
    OrderHistoriesSection,
    ReviewsPackageSection
  },
  data: () => ({
    loading: false,
    validate: false,
    formData: {
      name: '',
      description: '',
      type: '',
      imageUrls: [],
      vendor: null,
      duration: {
        days: 1,
        nights: 1
      },
      airline: '',
      accommodation: {
        type: '',
        star: null
      },
      meal: '',
      crews: 0,
      cancellable: false,
      destination: {
        continent: '',
        country: '',
        city: ''
      },
      specialOptions: [''],
      additionalDocuments: [],
      review: {
        rate: 0,
        total: 0
      },
      tags: [],
      galleries: [],
      highlights: [
        {
          title: '',
          description: '',
          imageUrl: []
        }
      ],
      flights: [
        {
          date: '',
          flightNo: '',
          seatMaps: [],
          departure: {
            location: '',
            time: ''
          },
          arrival: {
            location: '',
            time: ''
          }
        }
      ],
      plans: [
        {
          day: '',
          locations: [''],
          contents: [
            {
              time: '',
              icon: '',
              separate: false,
              content: ''
            }
          ]
        }
      ],
      packages: [
        {
          startDate: '',
          endDate: '',
          adult: {
            price: 0,
            withOutTicket: 0
          },
          kid: {
            price: 0,
            withOutTicket: 0
          },
          status: 'active'
        }
      ],
      rooms: [
        {
          name: 'พักคู่ (TWIN BED)',
          price: 0,
          capacity: 2
        },
        {
          name: 'พักคู่ (DOUBLE BED)',
          price: 0,
          capacity: 2
        },
        {
          name: 'พัก 3 ท่าน (TRIPLE BED)',
          price: 0,
          capacity: 3
        },
        {
          name: 'พักเดี่ยว (SINGLE BED)',
          price: 0,
          capacity: 1
        }
      ],
      insurances: [
        {
          name: '',
          description: '',
          protections: '',
          price: 0
        }
      ],
      seats: [
        {
          name: '',
          description: '',
          price: 0
        }
      ],
      meals: [],
      additionalRateConditions: {
        th: '',
        en: ''
      },
      additionalRateIncludes: [''],
      additionalRateNotIncludes: [''],
      cancelConditions: {
        th: '',
        en: ''
      },
      accommodationInformation: {
        th: '',
        en: ''
      },
      airlineInformation: {
        th: '',
        en: ''
      },
      additionalConditions: {
        th: '',
        en: ''
      },
      soldOut: false,
      status: 'available'
    }
  }),
  computed: {
    isEdit () {
      return !!this.$route.params?.id
    },
    user () {
      return this.$store.getters['User/user']
    },
    role () {
      return this.user?.role || 'alltotrips'
    }
  },
  async mounted () {
    if (this.isEdit) {
      this.getItemById()
    }
  },
  methods: {
    onBack () {
      this.$router.push({ name: 'TourList' })
    },
    async getItemById () {
      try {
        this.loading = true

        const { data } = await ToursService.getItemById(this.$route.params.id)

        const tmpForm = {
          ...this.formData,
          ...data
        }

        this.formData = {
          ...tmpForm,
          imageUrls: tmpForm.imageUrls.map((img) => ({
            file: null,
            preview: img,
            mediaUrl: img
          })),
          galleries: tmpForm.galleries.map((img) => ({
            file: null,
            preview: img,
            mediaUrl: img
          })),
          highlights: tmpForm.highlights.map((h) => ({
            ...h,
            imageUrl: h.imageUrl ? [{
              file: null,
              preview: h.imageUrl,
              mediaUrl: h.imageUrl
            }] : []
          })),
          flights: tmpForm.flights.map((f) => ({
            ...f,
            seatMaps: f.seatMaps.map((img) => ({
              file: null,
              preview: img,
              mediaUrl: img
            }))
          }))
        }
      } catch (error) {
        console.error('getItemById', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    },
    async onSubmit () {
      try {
        this.loading = true
        const valid = await this.$refs['form'].validate()

        if (!valid) {
          return
        }

        const imageUrls = await Promise.all(this.formData.imageUrls.map(async (img) => {
          if (img?.file) {
            const { data: image } = await UploaderService.uploadFile(img.file)

            return image?.publicUrl
          }

          return img?.mediaUrl || ''
        }))

        const highlights = await Promise.all(this.formData.highlights.map(async (h) => {
          if (h?.imageUrl[0]?.file) {
            const { data: image } = await UploaderService.uploadFile(h.imageUrl[0].file)

            return {
              ...h,
              imageUrl: image?.publicUrl || ''
            }
          }

          return {
            ...h,
            imageUrl: h?.imageUrl[0]?.mediaUrl || ''
          }
        }))

        const flights = await Promise.all(this.formData.flights.map(async (flight) => {
          const seatMaps = await Promise.all(flight.seatMaps.map(async (img) => {
            if (img?.file) {
              const { data: image } = await UploaderService.uploadFile(img.file)

              return image?.publicUrl || ''
            }

            return img?.mediaUrl || ''
          }))

          return {
            ...flight,
            seatMaps
          }
        }))

        const galleries = await Promise.all(this.formData.galleries.map(async (img) => {
          if (img?.file) {
            const { data: image } = await UploaderService.uploadFile(img.file)

            return image?.publicUrl
          }

          return img?.mediaUrl || ''
        }))

        const additionalDocuments = await Promise.all(this.formData.additionalDocuments.map(async (doc) => {
          if (doc?.file) {
            const { data } = await UploaderService.uploadFile(doc.file)

            return {
              name: doc.name,
              mediaUrl: data?.publicUrl || ''
            }
          }

          return {
            name: doc.name,
            mediaUrl: doc?.mediaUrl || ''
          }
        }))

        const packagePrice = {
          adult: { ...this.formData.packages[0].adult },
          kid: { ...this.formData.packages[0].kid }
        }
        const packages = this.formData.packages.map((p) => ({
          ...p,
          ...packagePrice
        }))

        if (!this.isEdit) {
          await ToursService.create({
            ...this.formData,
            packages,
            imageUrls,
            highlights,
            galleries,
            flights,
            additionalDocuments
          })
        } else {
          await ToursService.updateItemById({
            ...this.formData,
            packages,
            imageUrls,
            highlights,
            galleries,
            flights,
            additionalDocuments
          })
        }

        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: `${this.isEdit ? 'แก้ไข' : 'เพิ่ม'} ข้อมูลเสร็จสิ้น`,
          type: 'success',
          timeout: 2000
        })

        this.$router.push({ name: 'TourList' })
      } catch (error) {
        console.error('onSubmit', error)
        this.$store.dispatch('Snackbar/setSnackbar', {
          active: true,
          text: error?.message || error,
          type: 'error',
          timeout: 2000
        })
      } finally {
        this.loading = false
      }
    }
  }
}
</script>

<style scoped>
.form-container {
  background-color: white;
  border: 1px solid #E0E0E0;
}
</style>

<style>
.v-expansion-panel:not(:first-child)::after {
  border-top: none !important;
}
</style>
